.content_datenschutz {
    margin-top: 130px;
    padding: 20px;
}

@media (max-width: 768px) {
    .content_datenschutz {
        margin-top: 60px;
        padding: 20px;
    }   
}