/* Services.css */
.homepage {
    /*margin: 130px 0 0 0;*/
}

.hero-slider {
    position: relative;
    width: 100%;
    height: 100vh; /* Full viewport height */
    overflow: hidden;
}

.hero-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover; /* Scale image to fill container */
    background-position: center; /* Center image */
    background-repeat: no-repeat; /* Prevent image repetition */
    opacity: 0; /* Adjust opacity */
    transition: opacity 1s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align items horizontally */
    justify-content: center; /* Center align items vertically */
    color: var(--text-color);
    text-align: center; /* Ensure text is centered */
}

.hero-slide.active {
    opacity: 1;
}

.hero-slide h3 {
    font-size: calc(1rem + 0.5vw); /* Dynamic font size */
    margin-bottom: 10px;
    color: var(--text-color-gold);
    font-family: var(--font-family-title) !important;
    font-weight: 300;
}

.hero-slide h2 {
    color: var(--text-color);
    font-size: calc(2rem + 2vw); /* Dynamic font size */
    margin-bottom: 10px;
}

.hero-slide p {
    color: var(--text-color);
    font-size: calc(0.75rem + 0.25vw); /* Dynamic font size */
    margin-bottom: 40px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.hero-slide button {
    padding: 10px 20px;
    background-color: transparent;
    border: 2px solid #dfc697;
    color: var(--text-color) !important;
    cursor: pointer;
    font-family: var(--font-family-title) !important;
    font-size: var(--font-size-h5) !important;
    border-radius: 20px;
    transition: background-color 0.3s, color 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.hero-slide button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--service-color-frame);
    transition: left 0.4s ease-in-out;
    z-index: 0;
}

.hero-slide button:hover::before {
    left: 0;
}

.hero-slide button:hover {
    color: var(--text-color);
}

.hero-slide button span {
    position: relative;
    z-index: 3; /* Ensure the text is on the top layer */
}

.hero-slide button:hover span {
    color: var(--text-color);; /* Text color on hover */
}

.hero-slider-controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
}

.hero-slider-controls button {
    width: 10px;
    height: 10px;
    background-color: var(--backgroud-color);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
}

.hero-slider-controls button.active {
    background-color: var(--backgroud-color);
}

.hero-slider-arrow {
    font-size: 20px !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: 2px solid #dfc697;
    color: var(--text-color-gold);
    cursor: pointer;
    font-size: 2rem;
    border-radius: 50%;
    width: 6dvh;
    height: 6dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
}

.hero-slider-arrow:hover {
    background-color: var(--text-color-gold);
    color: var(--text-color);
}

.hero-slider-arrow.left {
    left: 20px;
}

.hero-slider-arrow.right {
    right: 20px;
}

@media (max-width: 866px) {
    .homepage {
        /*margin: 70px 0 0 0;*/
   }

    .hero-slider {
        height: 100vh; /* Maintain full viewport height */
    }

    .hero-slide h2 {
        margin-left: 50px;
        margin-right: 50px;
        font-size: 6vw; /* Dynamic font size based on viewport width */
        text-align: center;
    }

    .hero-slide p {
        font-size: 2.5vw; /* Dynamic font size based on viewport width */
        margin-left: 70px;
        margin-right: 70px;
        text-align: center;
    }

    .hero-slide button {
        font-size: 3vw; /* Dynamic font size based on viewport width */
    }

    .hero-slider-arrow {
        font-size: 3dvh;
        width: 30px;
        height: 30px;
    }
}

@media (min-width: 1200px) {
    .hero-slider {
        height: 100vh; /* Maintain full viewport height */
    }

    .hero-slide h2 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .hero-slide p {
        margin-left: 70px;
        margin-right: 70px;
    }
}
