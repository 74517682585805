.services-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden; /* Verhindert horizontales Scrollen */
}

.services-content {
  width: 100%;
  padding: 20px;
  margin-top: 120px; /* Platz für die TopBar */
}

.services-title {
  font-family: var(--font-family-title);
  font-size: var(--font-size-h1);
  /*color: var(--text-color-gold);*/
  margin: var(--spacing-medium) 0;
  text-align: center;
  padding: 20px;
}

.service-item-container {
  width: 100%;
}

.service-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: var(--spacing-large);
  background-color: var(--background-color);
  color: var(--text-color);
}

.service-item.reverse {
  flex-direction: row-reverse;
}

.service-image {
  width: 50%;
  height: 300px;
  overflow: hidden;
  opacity: 0.4;
}

.service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: drop-shadow();
  border-radius: 20px;
}

.service-image img:hover {
  filter: none;
}

.service-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  text-align: center;
}

.service-details h3 {
  margin-bottom: var(--spacing-small);
}

.service-details p {
  /*color: var(--teaser-color);*/
  margin-bottom: var(--spacing-large);
  text-align: center;
  padding: 30px;
}

.service-details button {
  padding: var(--spacing-small) var(--spacing-large);
  background-color: transparent !important;
  color: var(--text-color) !important;
  border: 2px solid var(--service-color-frame) !important;
  cursor: pointer !important;
  font-family: var(--font-family-title) !important;
  font-size: var(--font-size-p) !important;
  border-radius: 30px !important;
  position: relative !important;
  overflow: hidden !important;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out !important;
  z-index: 0 !important;
}

.service-details button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  /*background-color: var(--service-color-frame) !important;*/
  transition: left 0.4s ease-in-out !important;
  z-index: 1 !important;
}

.service-details button:hover::before {
  left: 0 !important;
}

.service-details button:hover {
  color: var(--text-color-gold) !important; /* Textfarbe beim Hover */
  z-index: 100;
}

.service-details button span {
  position: relative !important;
  z-index: 1000 !important;
}

.service-details button:hover span {
  color: var(--text-color-gold) !important; 
  z-index: 100;
}

@media (max-width: 866px) {
  .services-content {
    margin-top: 70px;
  }

  .service-item {
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--spacing-large);
  }

  .service-item.reverse {
    flex-direction: column;
  }

  .service-image, .service-details {
    width: 100%;
  }

  .service-image {
    margin-bottom: var(--spacing-large);
  }

  .service-details {
    padding: var(--spacing-medium);
  }
}
