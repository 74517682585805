.language-switcher {
    background-color: transparent; /* Transparenter Hintergrund */
    position: absolute; /* Absolute Positionierung */
    top: 18px; /* Abstand vom oberen Rand */
    right: 40px; /* Abstand vom rechten Rand */
    display: inline-block; /* Inline-Block-Anzeige */
  }
  
  .language-switcher a {
    font-size: var(--font-size-menu);
    font-family: var(--font-family-menu); /* Schriftart */
  }
  
  .language-switcher a:hover {
    color: var(--text-color-gold); /* Textfarbe beim Hover */
  }
  
  .language-switcher .dropdown-button {
    font-size: var(--font-size-menu);
    font-family: var(--font-family-menu); /* Schriftart */
    background-color: transparent; /* Transparenter Hintergrund */
    color: var(--text-color); /* Textfarbe weiß */
    border: none; /* Kein Rahmen */
    cursor: pointer; /* Mauszeiger wird zum Zeiger */
    padding: var(--spacing-small); /* Innenabstand */
  }
  
  .language-switcher .dropdown-menu {
    position: absolute; /* Absolute Positionierung */
    right: 0; /* Rechts ausgerichtet */
    top: 100%; /* Direkt unter dem übergeordneten Element */
    background-color: var(--navbar-background); /* Hintergrundfarbe */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Schatten */
    z-index: 1000; /* Über anderen Elementen */
  }
  
  .language-switcher .dropdown-menu button {
    display: flex; /* Flexbox-Layout */
    align-items: center; /* Vertikal zentriert */
    justify-content: flex-start; /* Links ausrichten */
    width: 100%; /* Volle Breite */
    padding: var(--spacing-small); /* Innenabstand */
    text-align: left; /* Text linksbündig */
    background-color: transparent; /* Transparenter Hintergrund */
    border: none; /* Kein Rahmen */
    color: var(--text-color); /* Textfarbe weiß */
    font-size: var(--font-size-menu);
    font-family: var(--font-family-menu); /* Schriftart */
    cursor: pointer; /* Mauszeiger wird zum Zeiger */
  }
  
  .language-switcher .dropdown-menu img {
    margin-right: 8px; /* Abstand rechts */
  }
  
  .language-switcher .dropdown-menu button:hover {
    color: var(--text-color-gold); /* Textfarbe beim Hover */
    background-color: var(--language-background-color); /* Hintergrundfarbe beim Hover */
  }

  .flag-icon {
    width: 18px; /* Passen Sie die Größe der Flaggen nach Bedarf an */
    height: auto;
    margin-right: 8px; /* Abstand zwischen Flagge und Text */
  }
  
  /* Mobile Styles */
  @media (max-width: 1200px) {
    .language-switcher {
      top: 18px; /* Abstand vom oberen Rand */
      right: 80px; /* Abstand vom rechten Rand */
      font-size: 18px; /* Schriftgröße */
    }
  }
  
  @media (max-width: 866px) {
    .language-switcher {
      right: 130px; /* Abstand vom rechten Rand */
    }
  }
  