.TopBar {
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: top 0.3s; /* Übergangseffekt hinzufügen */
}

.TopBar.hidden {
  top: -60px; /* TopBar verstecken */
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap; /* Kein Umbruch */
}

.social {
  display: flex;
  align-items: center;
  margin-right: auto; /* Social Icons linksbündig */
  font-size: large;
  color: inherit;
}

.social a {
  margin: 0 10px; /* Abstand zwischen den Icons */
  color: var(--text-color) !important; /* Icons immer weiß */
}

.social a:hover {
  color: var(--text-color-gold) !important; /* Farbänderung beim Hover */
  transform: scale(1.4); /* Vergrößert das Icon um 20% beim Hover */
}

.adress, .tel, .open, .opening-time {
  display: flex; /* Stellt sicher, dass Icons und Text nebeneinander stehen */
  align-items: center;
  margin: 0 5px !important;
  white-space: nowrap; /* Verhindert Zeilenumbruch */
}

.adress a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.adress span, .tel span, .open span, .opening-time span {
  font-family: var(--font-family-topbar);
  font-size: var(--font-size-topbar);
  margin-bottom: 2px; /* Verringert den Abstand zwischen den Zeilen */
  white-space: nowrap; /* Verhindert Zeilenumbruch */
}

.whatsapp {
  margin-left: auto; /* WhatsApp-Icon rechtsbündig */
}

.whatsapp a {
  color: var(--whatsapp-color) !important; /* Beibehaltung der Originalfarbe des Icons */
}

.whatsapp a:hover {
  transform: scale(1.4); /* Vergrößert das Icon um 20% beim Hover */
}

.icon-small {
  margin-right: 10px; /* Abstand zwischen Icon und Text */
}

/* Media Queries für mobile Geräte */
@media (max-width: 922px) {
  .TopBar {
    display: none !important; /* Versteckt die Elemente auf mobilen Geräten */
  }
}
