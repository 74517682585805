/* CSS Variablen für Farben und Schriftarten */

/* Webfonts: Bebas Neue */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

/* Webfont: Roboto */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Webfont: Roboto Condensed */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

/* Webfont: Libre Franklin */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=League+Gothic:wdth@75..100&family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap');

/* Webfont: Oswald */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

/* Webfont: Montserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* Webfont: Karla */
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');

:root {
    /*****************************************
                  Light Layout 
    ******************************************/

    /*--background-color: #ffffff; 
    --language-background-color: #807676;
    --services-background: #ffffff;
    --navbar-background: #ffffff;
    --topbar-background: #ffffff;
    --text-color: #000000;       
    --text-color-gold: #daa94d;  
    --text-color-title: #daa94d;  
    --primary-color: #007bff; 
    --secondary-color: #6c757d;
    --success-color: #28a745;    
    --danger-color: #dc3545;     
    --info-color: #17a2b8;  
    --light-color: #f8f9fa; 
    --dark-color: #c0c3c6;  
    --teaser-color: #ffffff;
    --whatsapp-color: #25D366;
    --service-color-frame: #bf9c34;
    /*  Pricing Color */
    /*--price-text-color: #000000;
    --divider-color: #727274;
    --duration-button-color: #d0d0d4;
    --duration-button-text-color: #31316d;
    --abo-button-color: #daa94d;
    --abo-button-text-color: #080827;*/

    /*****************************************
                  Dark Layout 
    ******************************************/

    --background-color: #161718; 
    --language-background-color: #000000;
    /*--services-background: #161718;*/
    --services-background: var(--smoky-black-1);
    --navbar-background: #161718;
    --topbar-background: #161718;
    --text-color: #dcdada;
    --text-color-gold: #daa94d;
    --text-color-title: #daa94d;
    --primary-color: #007bff; 
    --secondary-color: #6c757d;
    --success-color: #28a745;    
    --danger-color: #dc3545;  
    --info-color: #17a2b8;
    --light-color: #f8f9fa;
    --dark-color: #c0c3c6;
    --teaser-color: #000000;
    --whatsapp-color: #25D366;
    --service-color-frame: #ffc107;
    /*  Pricing Color */
    --price-text-color: #daa94d;
    --divider-color: #727274;
    --duration-button-color: #fff;
    --duration-button-text-color: #31316d;
    --abo-button-color: #d0d0d4;
    --abo-button-text-color: #31316d;


    --gold-crayola: hsl(38, 61%, 73%);
    --quick-silver: hsla(0, 0%, 65%, 1);
    --davys-grey: hsla(30, 3%, 34%, 1);
    --smoky-black-1: hsla(40, 12%, 5%, 1);
    --smoky-black-2: hsla(30, 8%, 5%, 1);
    --smoky-black-3: hsla(0, 3%, 7%, 1);
    --eerie-black-1: hsla(210, 4%, 9%, 1);
    --eerie-black-2: hsla(210, 4%, 11%, 1);
    --eerie-black-3: hsla(180, 2%, 8%, 1);
    --eerie-black-4: hsla(0, 0%, 13%, 1);
    --white: hsla(0, 0%, 100%, 1);
    --white-alpha-20: hsla(0, 0%, 100%, 0.2);
    --white-alpha-10: hsla(0, 0%, 100%, 0.1);
    --black: hsla(0, 0%, 0%, 1);
    --black-alpha-80: hsla(0, 0%, 0%, 0.8);
    --black-alpha-15: hsla(0, 0%, 0%, 0.15);

    --font-family-text: 'Roboto', sans-serif;
    --font-family-topbar: 'Oswald', sans-serif;
    --font-family-menu: 'Oswald', sans-serif;
    --font-family-title: 'Bebas Neue', sans-serif;
    --font-family-body: 'Montserrat', 'Helvetica Neue', Arial, sans-serif;
    --font-family-teaser: 'Roboto', sans-serif;
    --font-family-service-titel: 'Roboto', 'Helvetica Neue', Arial, sans-serif;

    --font-size-topbar: 1rem;
    --font-size-menu: 1rem;

    --font-size-p: 0.9rem;
    --font-size-h1: 2.5rem;
    --font-size-h2: 2rem;
    --font-size-h3: 1.75rem;
    --font-size-h4: 1.5rem;
    --font-size-h5: 1.25rem;
    --font-size-h6: 1rem;

    /* Abstände */
    --spacing-small: 0.5em;
    --spacing-medium: 1rem;
    --spacing-large: 1.5rem;

    /* Grill Preisliste */
    --fontFamily-dm_sans: 'DM Sans', sans-serif;
    --fontFamily-forum: 'Forum', cursive;
    --fontSize-title-2: 2rem;
    --fontSize-title-3: 1.5rem;
    --fontSize-body-4: 1rem;
    --radius-24: 24px;
    --transition-1: 0.3s ease;

    /* Faktoren */
    --factor-992: 0.9;
    --factor-768: 0.8;
}

/* Allgemeine Stile */
body {
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: var(--font-family-body);
    margin: 0;
    padding: 0px;box-sizing: border-box;
    overflow: auto;
    overflow-wrap: break-word; /* Hinzufügen */
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0.5em 0;
    color: var(--text-color-title);
    font-weight: 100;
    font-family: var(--font-family-title);
}

h1 {
    font-size: var(--font-size-h1);
}

h2 {
    font-size: var(--font-size-h2);
}

h3 {
    font-size: var(--font-size-h3);
}

h4 {
    font-size: var(--font-size-h4);
}

h5 {
    font-size: var(--font-size-h5);
}

h6 {
    font-size: var(--font-size-h6);
}

p, ul, ol {
    font-family: var(--font-family-text);
    font-size: var(--font-size-p);
    color: var(--text-color);
}

p.italic {
    font-family: var(--font-family-body);
    font-style: italic;
}

p.light-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

p.light-text-italic {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-style: italic;
}

p.bold-text {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

/* Stile für Links */
a {
    color: #dfc697;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@media (max-width: 992px) {


  h1, h2, h3, h4, h5, h6 {
      
  }

  h1 {
    font-size: (var(--font-size-h1) * var(--factor-992));    
  }

  h2 {
    font-size: calc(var(--font-size-h2) * var(--factor-992));
  }

  h3 {
    font-size: calc(var(--font-size-h3) * var(--factor-992));      
  }

  h4 {
    font-size: calc(var(--font-size-h4) * var(--factor-992));
  }

  h5 {
    font-size: calc(var(--font-size-h5) * var(--factor-992));      
  }

  h6 {
    font-size: calc(var(--font-size-h6) * var(--factor-992));      
  }

  p {
    font-size: calc(var(--font-size-p) * var(--factor-992));      
  }
  
  ul li {
    font-size: calc(var(--font-size-p) * var(--factor-992));      
  }

  ol li{
    font-size: calc(var(--font-size-p) * var(--factor-992));      
  }

  p.italic {
    font-size: calc(var(--font-size-p) * var(--factor-992));      
  }

  p.light-text {
    font-size: calc(var(--font-size-p) * var(--factor-992));        
  }

  p.light-text-italic {
    font-size: calc(var(--font-size-p) * var(--factor-992));        
  }

  p.bold-text {
    font-size: calc(var(--font-size-p) * var(--factor-992));        
  }

  /* Stile für Links */
  a {
      
  }

  a:hover {
      
  }   
}

@media (max-width: 768px) {

  h1, h2, h3, h4, h5, h6 {
      
  }

  h1 {
    font-size: (var(--font-size-h1) * var(--factor-768));    
  }

  h2 {
    font-size: calc(var(--font-size-h2) * var(--factor-768));
  }

  h3 {
    font-size: calc(var(--font-size-h3) * var(--factor-768));      
  }

  h4 {
    font-size: calc(var(--font-size-h4) * var(--factor-768));
  }

  h5 {
    font-size: calc(var(--font-size-h5) * var(--factor-768));      
  }

  h6 {
    font-size: calc(var(--font-size-h6) * var(--factor-768));      
  }

  p {
    font-size: calc(var(--font-size-p) * var(--factor-768));      
  }
  
  ul li {
    font-size: calc(var(--font-size-p) * var(--factor-768));      
  }

  ol li{
    font-size: calc(var(--font-size-p) * var(--factor-768));      
  }

  p.italic {
    font-size: calc(var(--font-size-p) * var(--factor-768));      
  }

  p.light-text {
    font-size: calc(var(--font-size-p) * var(--factor-768));        
  }

  p.light-text-italic {
    font-size: calc(var(--font-size-p) * var(--factor-768));        
  }

  p.bold-text {
    font-size: calc(var(--font-size-p) * var(--factor-768));        
  }

  /* Stile für Links */
  a {
      
  }

  a:hover {
      
  }
}