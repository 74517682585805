.jordishape {
   display: flex;
   flex-direction: column;
   margin-bottom: 30px;
   margin-top: 170px; /* Abstand von 170px vom oberen Rand */
   margin-bottom: 30px;
   overflow-x: hidden; /* Verhindert horizontales Scrollen */
}

 h4 {
    text-align: left;
 }

 ul li {
    font-size: var(--font-size-p);
    text-align: left;
    margin-bottom: 5px; /* Hier den gewünschten Abstand einstellen */
 }