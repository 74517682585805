.sandra {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 170px; /* Abstand von 170px vom oberen Rand */
    margin-bottom: 30px;
    overflow-x: hidden; /* Verhindert horizontales Scrollen */
}

.image {
    
}

.sandra-logo img {
    margin-left: 20px;
    width: 250px;
    opacity: 0.6;
}