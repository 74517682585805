.contact {
  padding-top: 150px;
  padding-left: 20px;
  padding-right: 20px;
}

.map-container {
  width: 100%;
  height: 400px;
  border: 2px solid #ccc; /* Beispiel-Stil für Rand */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Beispiel-Stil für Schatten */
  border-radius: 10px; /* Beispiel-Stil für abgerundete Ecken */
  margin-bottom: 20px; /* Abstand zum Button */
}

.directions-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff; /* Blau */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.directions-button:hover {
  background-color: #0056b3; /* Dunkleres Blau beim Hover */
}
