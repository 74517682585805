.sugaring {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 170px; /* Abstand von 170px vom oberen Rand */
    overflow-x: hidden; /* Verhindert horizontales Scrollen */
}

h4 {
    text-align: left;
  }
  
  ul li, ol li {
    font-size: var(--font-size-p);
    text-align: left;
    margin-bottom: 10px; /* Vereinheitlichter Abstand */
  }
  
  /* Separate Regel für ul li, falls ein spezifischerer Abstand benötigt wird */
  ul li {
    margin-bottom: 5px; /* Spezifischer Abstand für ul li */
  }
  
  .image {
    width: 100%;
    height: auto;
    opacity: 0.4;
    margin-bottom: 20px;
  }
  
  @media (max-width: 992px) {
    .sugaring {
      margin-top: 70px;
    }
  
    ul li, ol li {
      font-size: calc(var(--font-size-p) * var(--factor-992)); 
    }
  }
  
  @media (max-width: 768px) {
    .sugaring {
      margin-top: 70px;
    }
  
    ul li, ol li {
      font-size: calc(var(--font-size-p) * var(--factor-768)); 
    }
  }
  
  /* Neue CSS-Klasse für hervorhebendes <strong>-Element */
  .highlight {
    color: var(--text-color-gold);
  }
  