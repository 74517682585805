.about-background,
.contact-background,
.faq-background,
.booking-background {
  position: relative;
  height: 100vh; /* Stellt sicher, dass der Hintergrund den gesamten sichtbaren Bereich einnimmt */
  width: 100%;
  overflow: auto; /* Ermöglicht das Scrollen, falls der Inhalt größer als der Bildschirm ist */
}

.services-background {
    background-color: var(--services-background)
}

.about-background::before,
.contact-background::before,
.faq-background::before,
.booking-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0.3; /* Setzt die Sichtbarkeit des Hintergrundbildes auf 20% */
  z-index: -1; /* Stellt sicher, dass der Inhalt über dem Hintergrundbild bleibt */
}

.home-background::before {
    background-image: url('./images/Behandlung_1_small.jpg'); /* Pfad zum Hintergrundbild für About */
}

.about-background::before {
    background-image: url('./images/Behandlung_2_small.jpg'); /* Pfad zum Hintergrundbild für About */
}

.contact-background::before {
  background-image: url('./images/Behandlung_3_small.jpg'); /* Pfad zum Hintergrundbild für Contact */
}

.faq-background::before {
  background-image: url('./images/Behandlung_4_small.jpg'); /* Pfad zum Hintergrundbild für FAQ */
}
/*
.booking-background::before {
  background-image: url('./images/Behandlung_5_small.jpg'); /* Pfad zum Hintergrundbild für Booking *//*
}*/