.header-treatment {
  width: 100%;
  text-align: center;
  padding: 20px 0;
}

.title-treatment {
  color: var(--text-color-gold);
  text-align: left;
  padding-left: 20px;
  font-family: var(--font-family-title);
  font-weight: 100;
  font-size: 2rem;
  margin: 0;
}

.main-content-treatment {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 00px;
  overflow-wrap: break-word; 
}

.section-treatment {
  flex: 1;
  background-color: var(--background-color);
  color: white;
  padding: 20px;
  text-align: center;
  width: 48%;
  max-width: 100%; 
  box-sizing: border-box; 
  overflow-wrap: break-word; 
}

.treatment,
.treatment-description {
  display: block;
  margin-bottom: 0.5em; 
  text-align: left;
}

.footer-treatment {
  width: 100%;
  background-color: var(--background-color);
  text-align: center;
}

.container-pricelist {
  width: 100%;
}

.pricelist-card-treatment {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
  border-radius: 10px;
}

.title-wrapper-service {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  width: 100%;
}

.treatment {
  font-family: var(--font-family-teaser);
  font-size: 1rem;
  color: var(--text-color-gold);
  padding-right: 100px;
}

.treatment-duration, .treatment-abo {
  padding: 2px 10px;
  margin-left: 10px;
  font-family: var(--font-family-service-titel);
  font-size: .8rem;
  border-radius: 8px;
  flex-shrink: 0;
}

.treatment-duration {
  background-color: var(--duration-button-color);
  color: var(--duration-button-text-color);
  margin-left: 10px;
}

.treatment-abo {
  background-color: var(--abo-button-color);
  color: var(--abo-button-text-color)/*
  margin-top: -20px;**/
}

.treatment-price {
  font-family: var(--font-family-teaser);
  font-size: 1.1rem;
  color: var(--price-text-color);
  white-space: nowrap;
  margin-right: 30px;
}

.treatment-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  margin-top: -20px;
}

.treatment-description {
  font-family: var(--font-family-teaser);
  font-size: 0.8rem;
  color: var(--text-color);
  text-align: left;
  /*width: 100%;*/
  padding-right: 100px;
}

.treatment-divider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  margin: 10px 10px;
}

.treatment-divider::before, .treatment-divider::after {
  content: "";
  width: 100%;
  height: 0.5px;
  background-color: var(--divider-color);
  margin: 3px 0;
}

@media (max-width: 992px) {
    .header-treatment {
        font-size: 5rem;
    }

    .title-treatment {
        font-size: 1.6rem;
    }

    .treatment-description, 
    .treatment-duration, 
    .treatment-abo {
        font-size: 0.7rem;
    }

    .main-content-treatment {
        flex-direction: column;
    }

    .section-treatment {
        width: 100%;
        margin-bottom: 20px;
    }

    .treatment-price {
        font-size: 0.9rem;
    }
    }

@media (max-width: 768px) {

  .header-treatment {
    font-size: 5rem;
  }
  
  .title-treatment {
    font-size: 1.6rem;
  }
  
  .treatment,
  .treatment2 {
  display: block;
  margin-bottom: 0.5em; /* Optional: fügt einen kleinen Abstand zwischen den Zeilen hinzu */
  font-size: 0.7rem;
}

  .container-pricelist {
    width: 100%;
    margin-left: -10px;
  }
  .treatment-description, 
  .treatment-duration, 
  .treatment-abo {
    font-size: 0.6rem;
  }
  
  .main-content-treatment {
    flex-direction: column;
  }

  .section-treatment {
    width: 100%;
    margin-bottom: 20px;
  }

  .treatment-price {
    font-size: 0.7rem;
  }
}