.navbar {
  background-color: var(--background-color); /* Hintergrundfarbe */
  position: fixed; /* Fixierte Position */
  top: 60px; /* Platz für die TopBar */
  z-index: 1000; /* Höherer Z-Index, um über anderen Elementen zu bleiben */
  display: flex;
  justify-content: space-between;
  align-items: center; /* Elemente vertikal zentrieren */
  padding: var(--spacing-medium); /* Padding mit CSS-Variable */
  width: 100%; /* Volle Breite des Viewports */
  height: 50px; /* Feste Höhe der Navbar */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Leichter Schatten */
  transition: background-color 0.3s, top 0.3s; /* Übergangseffekt für den Hintergrund und die Position */
}

.navbar.top {
  top: 0;
}

/* Spezifische Hintergrundfarben für verschiedene Seiten */
.navbar-home {
  background-color: transparent; /* Farbe für die Home-Seite */
}

.navbar-home.navbar.scrolled {
  background-color: var(--background-color); /* Farbe für die Home-Seite beim Scrollen */
}

.navbar-about {
  background-color: transparent; /* Farbe für die About-Seite */
}

.navbar-about.navbar.scrolled {
  background-color: var(--background-color); /* Farbe für die About-Seite beim Scrollen */
}

.navbar-services {
  background-color: transparent; /* Farbe für die Services-Seite */
}

.navbar-services.navbar.scrolled {
  background-color: var(--background-color); /* Farbe für die Services-Seite beim Scrollen */
}

.navbar-contact {
  background-color: transparent; /* Farbe für die Contact-Seite */
}

.navbar-contact.navbar.scrolled {
  background-color: var(--background-color); /* Farbe für die Contact-Seite beim Scrollen */
}

.navbar-booking {
  background-color: transparent; /* Farbe für die Booking-Seite */
}

.navbar-booking.navbar.scrolled {
  background-color: var(--background-color); /* Farbe für die Booking-Seite beim Scrollen */
}

.navbar-nadelepilation {
  background-color: transparent; /* Farbe für die Booking-Seite */
}

.navbar-nadelepilation.navbar.scrolled {
  background-color: var(--background-color); /* Farbe für die Booking-Seite beim Scrollen */
}

.navbar-datenschutz {
  background-color: var(--background-color); /* Farbe für die Booking-Seite beim Scrollen */
}

/* Layout der Navbar */
.navbar-left,
.navbar-center,
.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-left {
  justify-content: flex-start;
  width: 20%; /* 1/5 der Breite */
}

.navbar-center {
  justify-content: center;
  width: 60%; /* 3/5 der Breite */
}

.navbar-right {
  justify-content: flex-end;
  width: 20%; /* 1/5 der Breite */
}

/* Logo-Styling */
.navbar-logo {
  text-align: left; /* Logo linksbündig ausrichten */
}

.navbar-logo img {
  height: 35px;
}

/* Menü-Styling */
.navbar-menu {
  display: flex;
  justify-content: center; /* Menü zentrieren */
  gap: var(--spacing-large);
}

.navbar-menu.active::after {
  margin-top: 0px;
}

.menu-close {
  display: none;
}

.navbar-menu a {
  color: var(--text-color);
  text-decoration: none;
  font-family: var(--font-family-menu);
  font-size: var(--font-size-menu);
  line-height: 1.5;
}

.navbar-menu a:hover,
.navbar-menu a.active {
  color: var(--text-color-gold);
  text-decoration: none;
}

.navbar-menu a::after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background: #dfc697;
  transition: width 0.3s;
  margin-top: 10px;
}

.navbar-menu a:hover::after,
.navbar-menu a.active::after {
  width: 100%;
  height: 1px;
}

/* Language Switcher und WhatsApp Icon */
.language-switcher {
  background-color: aqua;
  display: flex;
  justify-content: flex-end; /* Rechtsbündig ausrichten */
  margin-right: 80px;
  margin-top: 0;
}

.whatsapp-icon {
  display: flex;
  justify-content: flex-end; /* Rechtsbündig ausrichten */
  margin-right: 50px;
}

.whatsapp-icon a {
  color: var(--whatsapp-color) !important;
  font-size: 20px;
  margin-left: var(--spacing-large);
}

.language-switcher a {
  color: var(--text-color);
  margin-left: var(--spacing-large);
}

/* Medienabfragen für responsives Design */
@media (max-width: 1200px) {
  .current-page {
    background: linear-gradient(to right, transparent 0%, white 30%, #05315d 100%);
    border-bottom-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-top: 20px;
    font-family: var(--font-size-menu);
    font-size: 30px;
    color: var(--background-color);
    text-align: center;
    width: 100%;
    grid-column: span 3;
  }

  .navbar-menu {
    flex-direction: column;
    gap: var(--spacing-small);
    position: fixed;
    top: 50px; /* Platz für die TopBar */
    right: 0;
    height: 100vh;
    width: 250px;
    background-color: var(--text-color);
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
  }

  .navbar-menu a, .dropdown-button {
    text-decoration: none;
    color: var(--background-color);
    font-family: var(--font-family-menu);
    font-size: 16px;
  }

  .navbar-menu a:hover, .dropdown-button:hover {
    text-decoration: double;
  }

  .navbar-menu.open {
    transform: translateX(0);
  }

  .navbar-menu a {
    font-size: 14px;
    padding: var(--spacing-small);
  }

  .menu-toggle {
    display: flex;
    justify-content: flex-end;
    font-size: 24px;
    cursor: pointer;
    z-index: 1001;
    position: absolute;
    right: 30px;
    top: 16px;
    padding: var(--spacing-small);
  }

  .navbar-menu .menu-close {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }

  .navbar-menu.open .menu-close {
    display: block;
  }

  .whatsapp-icon a {
    display: flex !important;
    position: absolute;
    top: 30px;
    right: 90px;
    font-size: 20px;
  }

  .language-switcher {
    margin-right: 50px;
  }
}

@media (max-width: 922px) {
  .navbar {
    top: 0px; /* Platz für die TopBar */
    height: 50px; /* Höhe der Navbar */
    display: flex;
    justify-content: flex-start; /* Elemente linksbündig ausrichten */
    align-items: center; /* Elemente vertikal zentrieren */
  }

  .navbar-left {
    justify-content: flex-start; /* Linken Bereich linksbündig ausrichten */
    width: auto; /* Breite automatisch */
  }

  .navbar-logo {
    text-align: left; /* Logo linksbündig ausrichten */
    height: 100%;
    display: flex;
    align-items: center; /* Vertikal zentrieren */
  }

  .whatsapp-icon a {
    right: 85px !important;
  }

  .menu-toggle {
    top: 16px;
  }

  .language-switcher {
    margin-right: -10px;
  }
}

@media (min-width: 1200px) {
  .menu-toggle {
    display: none;
  }

  .navbar-menu {
    justify-content: flex-end;
  }

  .language-switcher {
    right: 20px !important;
  }
}
