.microneedling {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 170px; /* Abstand von 170px vom oberen Rand */
  margin-bottom: 30px;
  overflow-x: hidden; /* Verhindert horizontales Scrollen */
}

.navbar-microneedling {
  background-color: var(--navbar-background); /* Hier die gewünschte Farbe einfügen */
}

h3 {
  text-align: left;
  display: block;
}

p {
  text-align: justify;
/*  margin-bottom: 20px;*/
  display: block;
  color: var(--text-color);
}

@media (max-width: 992px) {
  .microneedling {
  margin-top: 80px;
  }
}